import { Link } from 'react-router-dom'
import { To } from 'history'
import { ButtonBase } from '@mui/material'
import { SxProps } from '@mui/system'
import config from 'config'
import logoPV from 'assets/images/logo.png'
import logoPVIcon from 'assets/images/logoPV.png'

interface Props {
  reverse?: boolean
  isIcon?: boolean
  sx?: SxProps
  to?: To
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => (
  <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
    {isIcon ? (
      <img src={logoPVIcon} width={36} height={36} alt="LogoIcon" />
    ) : (
      <img src={logoPV} style={{ marginTop: '30px', transform: 'scale(0.8, 0.8)' }} alt="Logo" />
    )}
  </ButtonBase>
)

export default LogoSection
