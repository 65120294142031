import { profilesAdministrative, viewsProtectedAdministrative } from 'config'
import { NavItemType } from 'types/menu'

export const protectedMenu = (menu: NavItemType, profile: any) => {
  let children = menu.children

  if (!profilesAdministrative.includes(profile)) {
    children = children?.filter((e) => {
      return !viewsProtectedAdministrative.some((item) => e.url?.includes(item))
    })
  }

  return {
    ...menu,
    children
  }
}
