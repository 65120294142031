import Routes from 'routes'
import ThemeCustomization from 'themes'
import Locales from 'components/Locales'
import RTLLayout from 'components/RTLLayout'
import ScrollTop from 'components/ScrollTop'
import Snackbar from 'components/@extended/Snackbar'
import { UserProvider } from 'contexts/UserContext'
import { ToastContainer } from 'react-toastify'
import useConfig from 'hooks/useConfig'

import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  const { mode } = useConfig()

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <UserProvider>
              <>
                <Routes />
                <Snackbar />
                <ToastContainer
                  position="bottom-right"
                  theme={mode}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  style={{ width: '450px' }}
                />
              </>
            </UserProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  )
}

export default App
