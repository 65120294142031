import { apiConfig, graphConfig } from '../config'

export async function callProfileApi(accessToken: string, email: string) {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers
  }

  const URL = `${apiConfig.urlBase}/users/email/${email}`

  return fetch(URL, options)
    .then((response) => response.json())
    .catch((error) => console.log(error))
}

export async function callProfileImage(accessToken: string) {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'image/jpg')

  const options = {
    method: 'GET',
    headers: headers
  }

  const URL = `${graphConfig.graphMeEndpoint}/photo/$value`

  return fetch(URL, options)
    .then((response) => response.blob())
    .catch((error) => console.log(error))
}
