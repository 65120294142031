import { BookOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { NavItemType } from 'types/menu'

const aplicaciones: NavItemType = {
  id: 'applications',
  title: <FormattedMessage id="applications" />,
  type: 'group',
  children: [
    {
      id: 'load-files',
      title: <FormattedMessage id="load-files" />,
      type: 'item',
      url: '/load-files',
      icon: CloudUploadOutlined
    },
    {
      id: 'reports',
      title: <FormattedMessage id="reports" />,
      type: 'item',
      url: '/reports',
      icon: BookOutlined
    }
    // {
    //   id: 'process',
    //   title: <FormattedMessage id="process" />,
    //   type: 'item',
    //   url: '/process',
    //   icon: ClusterOutlined
    // }
  ]
}

export default aplicaciones
