import { QuestionCircleOutlined, SettingOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { NavItemType } from 'types/menu'

const other: NavItemType = {
  id: 'other',
  title: <FormattedMessage id="others" />,
  type: 'group',
  children: [
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'item',
      url: '/profile',
      icon: SolutionOutlined
    },
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'item',
      icon: TeamOutlined,
      url: '/users/list'
    },
    {
      id: 'configuration',
      title: <FormattedMessage id="configuration" />,
      type: 'collapse',
      icon: SettingOutlined,
      children: [
        {
          id: 'tax_year',
          title: <FormattedMessage id="tax_year" />,
          type: 'item',
          url: '/config/tax-year'
        }
      ]
    },
    {
      id: 'help',
      title: <FormattedMessage id="help" />,
      type: 'item',
      icon: QuestionCircleOutlined,
      url: '/help'
    }
  ]
}

export default other
