import { Box, Typography } from '@mui/material'
import menuItem from 'menu-items'
import { useSelector } from 'react-redux'
import { RootStateProps } from 'types/root'
import NavGroup from './NavGroup'
import { protectedMenu } from 'utils/role/ProtectedMenu'
import useAuth from 'hooks/useAuth'

const Navigation = () => {
  const menu = useSelector((state: RootStateProps) => state.menu)
  const { drawerOpen } = menu
  const { user } = useAuth()

  const navGroups = menuItem.items.map((item) => {
    if (item.id === 'applications' || item.id === 'other') {
      item = protectedMenu(item, user?.rol || '')
    }

    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        )
    }
  })

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>
}

export default Navigation
