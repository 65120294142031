import { useState } from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

interface Props {
  handleLogout: () => void
}

const ProfileTab = ({ handleLogout }: Props) => {
  const navigate = useNavigate()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, url: string) => {
    setSelectedIndex(index)
    navigate(url)
  }

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1, '/profile')}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="Ver Perfil" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  )
}

export default ProfileTab
