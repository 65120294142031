import { DefaultConfigProps } from 'types/config'
import { LogLevel } from '@azure/msal-browser'

export const drawerWidth = 260

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authority: process.env.REACT_APP_AUTHORITY || '',
    redirectUri: '/',
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) return

        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            // console.info(message)
            return
          case LogLevel.Verbose:
            // console.debug(message)
            return
          case LogLevel.Warning:
            // console.warn(message)
            return
        }
      }
    }
  }
}

export const loginRequest = {
  scopes: ['https://graph.microsoft.com/.default', 'openid']
}

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}

export const apiConfig = {
  urlBase: process.env.REACT_APP_API
}

const config: DefaultConfigProps = {
  defaultPath: '/home',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'es',
  miniDrawer: false,
  container: false,
  mode: 'light',
  presetColor: 'theme2',
  themeDirection: 'ltr'
}

export const profilesAdministrative: Array<string> = ['Nv.0']
export const viewsProtectedAdministrative: Array<string> = ['users', 'process']

export default config
