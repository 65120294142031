import { lazy } from 'react'

import Loadable from 'components/Loadable'
import CommonLayout from 'layout/CommonLayout'
import MainLayout from 'layout/MainLayout'
import AuthGuard from 'utils/route-guard/AuthGuard'
import { ProtectedRoute } from 'utils/role/ProtectedRoute'
import { profilesAdministrative } from 'config'

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')))
const MaintenanceUnauthorized = Loadable(lazy(() => import('pages/maintenance/401')))
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')))
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')))
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')))

const Profile = Loadable(lazy(() => import('pages/apps/profile/profile')))
const Home = Loadable(lazy(() => import('pages/apps/home/Home')))
const Report = Loadable(lazy(() => import('pages/apps/report/Report')))
const ReportPagination = Loadable(lazy(() => import('pages/apps/report/Report-Pagination')))
const LoadFiles = Loadable(lazy(() => import('pages/apps/load-files/load-files')))
const Process = Loadable(lazy(() => import('pages/apps/process/process')))

const TaxYear = Loadable(lazy(() => import('pages/apps/config/impuesto-anual/impuesto-anual')))

const Users = Loadable(lazy(() => import('pages/apps/users/users')))
const UsersNew = Loadable(lazy(() => import('sections/apps/users/UserNew')))
const UsersEdit = Loadable(lazy(() => import('sections/apps/users/UserEdit')))

const Help = Loadable(lazy(() => import('pages/apps/help/help')))

export const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: <Home />
        },
        {
          path: 'reports',
          element: <Report />
        },
        {
          path: 'reports-pagination',
          element: <ReportPagination />
        },
        {
          path: 'load-files',
          element: <LoadFiles />
        },
        {
          path: 'process',
          element: <Process />
        },
        {
          path: 'profile',
          element: <Profile />
        },
        {
          path: 'help',
          element: <Help />
        },
        {
          path: 'users',
          children: [
            {
              path: 'list',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <Users />
                </ProtectedRoute>
              )
            },
            {
              path: 'new',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <UsersNew />
                </ProtectedRoute>
              )
            },
            {
              path: 'edit/:uuid',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <UsersEdit />
                </ProtectedRoute>
              )
            }
          ]
        },
        {
          path: 'config',
          children: [
            {
              path: 'tax-year',
              element: (
                <ProtectedRoute roles={profilesAdministrative}>
                  <TaxYear />
                </ProtectedRoute>
              )
            }
          ]
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '401',
          element: <MaintenanceUnauthorized />
        },
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
}

export const routesNoMatch = {
  path: '*',
  element: <MaintenanceError />
}
