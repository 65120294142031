/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPublicClientApplication, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from 'config'
import React, { createContext, useEffect, useState } from 'react'
import { callMsGraph } from 'services/graph'
import { callProfileApi, callProfileImage } from 'services/profile'
import { AuthProps } from 'types/auth'
import { fetchLogs } from 'services/logs'
import { fetchAPI } from 'utils/fetchAPI'
import { User } from 'interface/custom'
import { toast } from 'react-toastify'

const initialState: AuthProps = {
  user: {
    id: 0,
    uuid: '',
    email: '',
    avatar: '',
    nombre: '',
    apellido: '',
    cargo: '',
    rut: '',
    rol: '',
    fecha_registro: '',
    estado: false,
    loading: true
  }
}

const lgo = 1

export const userContext = createContext<AuthProps>(initialState)

const logout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect({
    postLogoutRedirectUri: '/',
    onRedirectNavigate: () => {
      return false
    }
  })
}

export const UserProvider = ({ children }: { children: React.ReactElement }) => {
  const [user, setUser] = useState(initialState)
  const { instance, accounts, inProgress } = useMsal()
  const [apiData, setApiData] = useState(null)

  const handleLogin = () => {
    if (!apiData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then(async (accessTokenResponse) => {
          const accessToken = accessTokenResponse.accessToken

          sessionStorage.setItem('webApiAccessToken', accessTokenResponse.idToken)

          const responseGraph = await callMsGraph(accessToken)
          setApiData(responseGraph)

          let image = ''
          const responseProfile = await callProfileImage(accessToken)
          if (responseProfile) {
            const imageObjectURL = URL.createObjectURL(responseProfile as Blob)
            image = imageObjectURL
          }

          sessionStorage.setItem('user-access', '')

          const responseApiProfile: User = await callProfileApi(accessTokenResponse.idToken, responseGraph.mail)

          if (responseApiProfile === undefined) {
            toast.error('Error al iniciar sesión.', { autoClose: false })
            logout(instance)
          }

          if (responseApiProfile?.message) {
            toast.error(responseApiProfile.message, { autoClose: false })
            logout(instance)
          }

          if (responseApiProfile.estado === false) {
            toast.error('Cuenta desactivada.', { autoClose: false })
            logout(instance)
          }

          setUser({
            user: {
              id: responseApiProfile.id,
              uuid: responseApiProfile.uuid,
              email: responseGraph.mail,
              avatar: image,
              nombre: responseApiProfile.nombre,
              apellido: responseApiProfile.apellidos,
              nombreAD: responseGraph.displayName,
              cargo: responseGraph.jobTitle,
              rol: responseApiProfile.role.nombre,
              rut: responseApiProfile.rut,
              fecha_registro: responseApiProfile.create_at,
              estado: responseApiProfile.estado,
              loading: false
            }
          })
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            const accessTokenResponse: any = instance.acquireTokenRedirect({ ...loginRequest, account: accounts[0] })
            sessionStorage.setItem('webApiAccessToken', accessTokenResponse?.idToken)
          }
          console.log(error)
        })
    }
  }

  useEffect(() => {
    handleLogin()
  })

  return (
    <>
      <userContext.Provider value={user}>{children}</userContext.Provider>
    </>
  )
}
